<div #strings class="strings">
  <!-- Direction pipe strings -->
  <span id="dir_N" i18n>north</span>
  <span id="dir_E" i18n>east</span>
  <span id="dir_S" i18n>south</span>
  <span id="dir_W" i18n>west</span>
  <span id="dir_NW" i18n>northwest</span>
  <span id="dir_NE" i18n>northeast</span>
  <span id="dir_SW" i18n>southwest</span>
  <span id="dir_SE" i18n>southeast</span>

  <span id="techtype_DIGITAL" i18n>Digital</span>
  <span id="techtype_STATIC" i18n>Static</span>
  <span id="techtype_TRIVISION" i18n>Trivision</span>

  <span id="state_InventoryEvaluation" i18n>Inventory evaluation</span>
  <span id="state_ProposalCreation" i18n>Proposal creation</span>
  <span id="state_HoldNegotiation" i18n>Hold negotiation</span>
  <span id="state_ContractNegotiation" i18n>Contract negotiation</span>
  <span id="state_DesignNegotiation" i18n>Design negotiation</span>
  <span id="state_UnderContract" i18n>Under contract</span>
  <span id="state_ContractComplete" i18n>Contract complete</span>
  <span id="state_Cancelled" i18n>Cancelled</span>

  <span id="task_details" i18n>Edit details</span>
  <span id="task_inventory" i18n>Add inventory</span>
  <span id="task_availability" i18n>Determine availability</span>
  <span id="task_spreadsheet" i18n>Generate spreadsheet</span>
  <span id="task_photosheet" i18n>Generate photosheet</span>
  <span id="task_submitted" i18n>Submit proposal</span>
  <span id="task_returned" i18n>Proposal returned</span>
  <span id="task_addHolds" i18n>Add inventory to hold</span>
  <span id="task_requestHolds" i18n>Request holds</span>
  <span id="task_acceptHolds" i18n>Receive hold agreements</span>
  <span id="task_accepted" i18n>Proposal accepted</span>
  <span id="task_addContracts" i18n>Add inventory to contract</span>
  <span id="task_requestContracts" i18n>Request contracts</span>
  <span id="task_acceptContracts" i18n>Accept contracts</span>
  <span id="task_vinyl" i18n>Create vinyl</span>
  <span id="task_digital" i18n>Create digital</span>
  <span id="task_approval" i18n>Get creative approval</span>
  <span id="task_started" i18n>Campaign started</span>
  <span id="task_ended" i18n>Campaing ended</span>
  <span id="task_pop" i18n>Send POP report</span>
  <span id="task_removeCreative" i18n>Send removal confirmation</span>
</div>
