import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { ApolloModule } from 'apollo-angular';
import { ChooseOrganizationComponent } from './components/choose-organization/choose-organization.component';
import { ChooseUserComponent } from './components/choose-user/choose-user.component';
import { ChooseSegmentComponent } from './components/choose-segment/choose-segment.component';
import { ChooseStoredQueryComponent } from './components/choose-stored-query/choose-stored-query.component';
import { ColumnNamesComponent } from './components/column-names/column-names.component';
import { ErrorComponent } from './components/error/error.component';
import { MapComponent } from './components/map/map.component';
import { PipeNamesComponent } from './components/pipe-names/pipe-names.component';
import { PoiNamesComponent } from './components/poi-names/poi-names.component';
import { ContainerDirective } from './directives/container.directive';
import { TemplateDirective } from './directives/template.directive';
import { TitleDirective } from './directives/title.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { MaterialModule } from './material.module';
import { DistancePipe } from './pipes/distance.pipe';
import { FacingPipe } from './pipes/facing.pipe';
import { TechTypePipe } from './pipes/tech-type.pipe';
import { TranslateService } from './services';
import { ProposalStatePipe } from './pipes/proposal-state.pipe';
import { ProposalTaskPipe } from './pipes/proposal-task.pipe';
import { DialogCloseDirective } from './directives/dialog-close.directive';

@NgModule({
  imports: [
    GoogleMapsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ApolloModule,
    MaterialModule,
  ],
  declarations: [
    ContainerDirective,
    TemplateDirective,
    TitleDirective,
    AutofocusDirective,
    ChooseOrganizationComponent,
    ChooseUserComponent,
    ColumnNamesComponent,
    TechTypePipe,
    DistancePipe,
    FacingPipe,
    PipeNamesComponent,
    PoiNamesComponent,
    MapComponent,
    ChooseStoredQueryComponent,
    ChooseSegmentComponent,
    ErrorComponent,
    ProposalStatePipe,
    ProposalTaskPipe,
    DialogCloseDirective,
  ],
  providers: [TechTypePipe, DistancePipe, FacingPipe],
  exports: [
    ChooseOrganizationComponent,
    ChooseUserComponent,
    ContainerDirective,
    TemplateDirective,
    TitleDirective,
    AutofocusDirective,
    TechTypePipe,
    DistancePipe,
    FacingPipe,
    MapComponent,
    ChooseStoredQueryComponent,
    ChooseSegmentComponent,
    ProposalStatePipe,
    ProposalTaskPipe,
    DialogCloseDirective,
  ],
})
export class CoreModule {
  constructor(private translate: TranslateService) {
    try {
      this.translate.register('column', ColumnNamesComponent);
      this.translate.register('pipe', PipeNamesComponent);
      this.translate.register('poi', PoiNamesComponent);
    } catch (error) {
      // This may fail during testing, which is OK
    }
  }
}
